<template>
    <b-container class="mb-4">
        <b-card class="text-center">
            <router-link
                class="sc-link float-left"
                :to="{ name: 'view_committees' }"
            >
                <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
            </router-link>
            <h2>
                <font-awesome-icon
                    icon="sitemap"
                    class="mr-2"
                ></font-awesome-icon>
                Committees : {{ record.committee_name }}
            </h2>
            <p>{{ record.help_text }}</p>
            <div class="mt-3">
                <button
                    class="sc-btn pill"
                    @click="expireMembership()"
                >
                    <font-awesome-icon
                        icon="hourglass-end"
                        class="mr-2"
                    ></font-awesome-icon>
                    Expire All
                </button>
            </div>
        </b-card>
        <b-list-group
            class="custom-list-group mt-2"
        >
            <b-list-group-item
                class="custom-list-group-title"
            >
                <h4>
                    <font-awesome-icon
                        icon="users"
                        class="mr-2"
                    ></font-awesome-icon>
                    Committee Members
                </h4>
            </b-list-group-item>
            <b-skeleton-wrapper
                :loading="loading"
            >
                <template #loading>
                    <b-list-group-item>
                        <b-skeleton></b-skeleton>
                    </b-list-group-item>
                </template>
                <template v-if="record.members && record.members.length > 0">
                    <template
                        v-for="(member, idx) in record.members"
                    >
                        <b-list-group-item
                            v-if="!member.removed"
                            :key="idx"
                        >
                            <p>
                                {{ member.user.person.first_name }} {{ member.user.person.last_name }}
                                <b-badge
                                    v-if="checkExpired(member.expires)"
                                    variant="danger"
                                    class="ml-2"
                                >
                                    <font-awesome-icon
                                        icon="exclamation-triangle"
                                        class="mr-1"
                                    ></font-awesome-icon>
                                    Expired {{ $moment(member.expires).format("LLLL") }}
                                </b-badge>
                                <b-badge
                                    v-else
                                    variant="sc-badge"
                                    class="ml-2"
                                >
                                    <font-awesome-icon
                                        icon="info-circle"
                                        class="mr-1"
                                    ></font-awesome-icon>
                                    Expires {{ $moment(member.expires).format("LLLL") }}
                                </b-badge>
                                <br/>
                                <a
                                    class="sc-link"
                                    :href="'mailto:' + member.user.email"
                                >{{ member.user.email }}</a>
                            </p>
                            <div class="text-center">
                                <a
                                    class="sc-btn pill p-2"
                                    :href="'mailto:' + member.user.email"
                                >
                                    <font-awesome-icon
                                        icon="envelope"
                                        class="mr-2"
                                    ></font-awesome-icon>
                                    Email
                                </a>
                                <button
                                    class="sc-btn pill ml-2"
                                    @click="expireMembership(member)"
                                >
                                    <font-awesome-icon
                                        icon="hourglass-end"
                                        class="mr-2"
                                    ></font-awesome-icon>
                                    Expire now
                                </button>
                            </div>
                        </b-list-group-item>
                    </template>
                </template>
                <b-list-group-item
                    v-else
                    class="text-muted"
                >
                    <font-awesome-icon
                        icon="info-circle"
                        class="mr-2"
                    ></font-awesome-icon>
                    <i>This committee has no members.</i>
                </b-list-group-item>
            </b-skeleton-wrapper>
        </b-list-group>
    </b-container>
</template>
<script>
export default {
    props: ["id"],
    data() {
        return {
            record  : {},
            loading : false
        }
    },
    mounted() {
        this.loadRecord();
    },
    methods: {
        checkExpired(expires) {
            return parseInt(new Date().toISOString().replace(/[^\d]/g, "")) > 
                        parseInt(expires.replace(/[^\d]/g, "") + "000");
        },
        loadRecord() {
            this.loading = true;
            this.$api.get(
                "/api/committees/admin/fetch-committee",
                { params: {
                    id: this.id
                } }
            ).then(({ data }) => {
                this.record  = data.record;
                this.loading = false;
            })
        },
        expireMembership(member) {
            this.$api.post(
                "/api/committees/admin/expire-membership",
                {
                    committee_id : this.id,
                    user_id      : (member) ? member.user.id : null
                }
            ).then(({ data }) => {
                if (data.success)
                {
                    if (member)
                        member.expires = data.expires;
                    else
                        this.record.members.forEach((member) => {
                            member.expires = data.expires;
                        });
                }
                this.$bvToast.toast(data.message, {
                    title           : (data.success) ? "Success!" : "Error!",
                    variant         : (data.success) ? "success"  : "danger",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
            });
        }
    }
}
</script>